<template>
    <div class="template">
        <Appbar :title="`My Profile Setting`">
            <template v-slot:customizedTitle>
                My Profile Setting
            </template>
        </Appbar>

        <div class="input-entity bottom-area watchable-password">
            <label>Change Passcode</label>
            <div class="input-area" style="margin-bottom: 16px;">
                <input :type="passwordInputType" class="input-password" v-model="password"
                    placeholder="Enter your password"/>
                <button class="button open-input-password" @click.prevent="passwordIsOpen = !passwordIsOpen">
                    <img width="auto" v-if="passwordIsOpen" src="../assets/input/ico_visibility-on.svg">
                    <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">
                </button>
            </div>

            <div class="input-area">
                <input :type="passwordReInputType" class="input-password" v-model="password_re"
                    placeholder="Enter your password again"/>
                <button class="button open-input-password" @click.prevent="passwordReIsOpen = !passwordReIsOpen">
                    <img width="auto" v-if="passwordReIsOpen" src="../assets/input/ico_visibility-on.svg">
                    <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">
                </button>
            </div>
        </div>

        <div class="section-wrapper">
            <div class="container" style="display: flex; padding: 0 0 24px;">
                <button style="flex: 1; font-size: 14px;" class="button positive font-size-positive"
                        @click="changeApply">Save
                </button>
            </div>
            <div class="container" style="display: flex; padding: 0 0 24px;">
                <button style="flex: 1; font-size: 14px;" class="button secondary" @click="changeCanceled">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import Appbar from '../components/Appbar';
import { mapGetters } from 'vuex';
import httpClient, { API_HOST } from '../httpClient';

export default {
    name: 'EditProfile',
    components: {
        Appbar,
    },
    computed: {
        ...mapGetters(['profile']),
        passwordInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.passwordIsOpen);
            },
        },
        passwordReInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.passwordReIsOpen);
            },
        },
    },
    methods: {
        changeCanceled() {
            this.resetInput();
            this.$router.go(-1);
        },
        async changeApply() {
            this.name = this.name.trim();

            // 만약 패스워드 항목을 두개 다 비워놓는다면, true로 해서 빈값으로 보냅니다. 다만 첫번째 패스워드 항목에 입력을 한다면 두번째 항목과 같은지 검사합니다.
            if ((this.password || this.password_re) && this.password !== this.password_re) {
                alert('check your password!');
                return false;
            }

            let requestPasswordEdit = false;
            if ((this.password && this.password_re) && this.password === this.password_re) {
                requestPasswordEdit = true;
            }

            let successful = false;

            try {
                const { data } = await httpClient.post(API_HOST('/__authorize/profile/edit'), {
                    name: this.name,
                    phone: this.phone,
                    password: requestPasswordEdit ? this.password : null,
                });

                if (data.success) {
                    successful = true;

                    // refresh profile after editing profile;
                    try {
                        await this.$store.dispatch('refreshDashboard');
                    } catch (e) {
                        console.log(`could not load edited profile: `, e);
                    }
                }

            } catch (e) {

                console.log('could edit profile: ', e);
                alert(`could edit profile. error: ${e.message}`);

            } finally {
                if (successful) {
                    alert(`profile${requestPasswordEdit ? ' and password' : ''} has been edited.`);
                    this.$router.replace('/profile');
                }
            }

        },
        resetInput() {
            this.name = this.profile.name ? this.profile.name : '';
            this.phone = this.profile.phone ? this.profile.phone : '';
            this.password = '';
            this.password_re = '';
        },
        getTypeOfInputForPassword(isOpen) {
            if (isOpen) {
                return 'text';
            } else {
                return 'password';
            }
        },
    },
    data() {
        return {
            name: '',
            phone: '',
            password: '',
            password_re: '',
            passwordIsOpen: false,
            passwordReIsOpen: false,
        };
    },
    mounted() {
        this.resetInput();
    },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
    padding: 20px 16px 0;
    margin-bottom: 2em;
}

</style>
