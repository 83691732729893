<template>
    <div class="balance">
        <Appbar title="STAKE" />
        <div class="cryptocurrency-list">
            <div class="cryptocurrency">
                <div>
                    <div class="currency-name">Stake</div>
                    <div class="input-entity">
                        <label>Amount of DECE ( You Have : {{ (Math.floor(this.currencies['dece'].balance * 1000000) / 1000000) }} DECE )</label>
                        <div class="btnArea text-align-right">
                            <input class="black" style="width: calc(100% - 100px)" type="number" v-model="amount" step="100" min="0"  value="0" v-on:blur="handleblur" placeholder="Enter amount to stake"/>
                            <button style="margin-left: 8px; width: 80px; border-radius: 8px; background-color: #0c0f14; border: 1px solid #2196f3;" class="button" @click="putAmountToMaximum">
                                Max
                            </button>
                        </div>

                        <div style="margin-top: 8px; font-size: 14px;">
                            Interst Rate : 10% Per Year, Daily Distribution.<br/>
                            Lockup Period : 365 Days ( = 1 Year )
                        </div>
                        <div class="btnArea">
                            <button style="margin-top: 8px; font-size: 14px;" class="button positive" @click="stake">Stake</button>
                        </div>
                        <!-- <div> Daily Limit Left : {{ daily_limit }} BNB / {{ 0.05 }} BNB</div> -->
                    </div>

                </div>
            </div>
        </div>

        <h2 class="transaction-history-title balance-padding-lr">Staking History</h2>
        <div class="balance-padding-lr unemphasis" v-if="!list">
            Loading Staking History...
        </div>
        <section class="transaction-list balance-padding-lr" v-else-if="list.length >= 1">
            <div class="transaction" v-for="transaction in list" :key="transaction.txid" @click="openExplorer(transaction.txid)">
                <div class="txn-left">
                    <div class="txn-info-area">
                        <div style="word-break:break-all;" class="txn-info-txid">{{ transaction.txid }}</div>
                        <div class="txn-info-date">Start : {{ getDateOnlyFormatted(transaction.timestamp) }}</div>
                        <div class="txn-info-date">End : {{ getDateOnlyFormatted((new Date(new Date(transaction.timestamp)).getTime() + 31536000000)) }}</div>
                    </div>
                </div>

                <div class="txn-right">
                    <span class="txn-amount">{{ calcDisplayAmount(transaction.amount, 4) }}</span> <span class="txn-unit">DECE</span>
                    <br />
                    <span class="txn-amount">{{ calcDisplayAmount(transaction.amount * 0.1 / 365, 4) }}</span> <span class="txn-unit">DECE Per Day</span>
                    <template v-if="transaction.fee">
                        <br />
                        <span class="txn-amount">Fee : {{ calcDisplayAmount(transaction.fee, 4) }}</span> <span class="txn-unit">{{ keyCurrencyFee.toLocaleUpperCase() }}</span>
                    </template>
                </div>
            </div>
        </section>
        <div class="balance-padding-lr unemphasis" v-else>
            Staking history is empty
        </div>


        <h2 class="transaction-history-title balance-padding-lr">Staking Interest History</h2>
        <div class="balance-padding-lr unemphasis" v-if="!interesetList">
            Loading Staking Interest History...
        </div>
        <section class="transaction-list balance-padding-lr" v-else-if="list.length >= 1">
            <div class="transaction" v-for="transaction in interesetList" :key="transaction.txid" @click="openExplorer(transaction.txid)">
                <div class="txn-left">
                    <div class="txn-info-area">
                        <div style="word-break:break-all;" class="txn-info-txid">{{ transaction.txid }}</div>
                        <div class="txn-info-date">{{ getDateFormatted(transaction.timestamp) }}</div>
                    </div>
                </div>

                <div class="txn-right">
                    <span class="txn-amount">{{ calcDisplayAmount(transaction.value, 4) }}</span> <span class="txn-unit">DECE</span>
                    <template v-if="transaction.fee">
                        <br />
                        <span class="txn-amount">Fee : {{ calcDisplayAmount(transaction.fee, 4) }}</span> <span class="txn-unit">{{ keyCurrencyFee.toLocaleUpperCase() }}</span>
                    </template>
                </div>
            </div>
        </section>
        <div class="balance-padding-lr unemphasis" v-else>
            Staking history is empty
        </div>
        <div style="margin-bottom: 180px;">

        </div>
        <FooterNavbar />
    </div>
</template>

<script>
import Appbar from "../components/Appbar";
import FooterNavbar from "../components/FooterNavbar";
import CurrencyPage from "../mixins/CurrencyPage";
import {toFixedNumberAndLocaleString} from "../components/utils";
// import LineChart from '../components/LineChart.js'

import httpClient, { API_HOST } from "../httpClient";

import {mapGetters} from "vuex";

export default {
    mixins: [CurrencyPage],
    components: {
        Appbar,
        FooterNavbar,
        // LineChart,
    },
    data() {
        return {
            receive: 0,
            amount: 0,
            amount_dece: 0,
            amount_c3p: 0,
            daily_limit: 0.05,
            c3p_price: 0,
            list: [],
            interesetList: [],
        };
    },
    computed: {
        ...mapGetters(['currencies', 'rates']),
    },
    async mounted() {
        const { data : stakingList } = await httpClient.get(API_HOST('/__authorize/stake'));
        console.log(stakingList)
        this.list = stakingList.data;

        const { data : stakingInterestList } = await httpClient.get(API_HOST('/__authorize/stake/interest'));
        console.log(stakingList)
        this.interesetList = stakingInterestList.data;
        //console.log(this.list)
        const { data } = await httpClient.get(API_HOST('/__authorize/profile/daily-limit'));
        this.daily_limit = data.daily_limit;
        const c3p_price = (parseFloat(this.currencies['c3p'].price) * 1 / 1);
        this.c3p_price = c3p_price;

    },
    methods: {
        handleblur(e) {
            console.log('blur', e.target.value)
            const result = Math.floor(e.target.value / 100) * 100;
            if(result) {
                this.amount = (result) ? result : e.target.min;
            }
        },
        async stake() {
            if(this.amount <= 0) {
                alert("Input should be more than 0");
                return;
            }
            if(this.amount % 100 > 0) {
                alert("Should be multiple of 100");
                return;
            }

            if (confirm(`Do you want really stake ${this.amount} DECE 1 Year?`) == false) {
                alert("User cancled staking");
                return;
            }

            const { data } = await httpClient.post(API_HOST('/__authorize/stake/dece'), {
                amount: this.amount,
            });


            /*
            const { data : limit } = await httpClient.get(API_HOST('/__authorize/profile/daily-limit'));
            this.daily_limit = limit.daily_limit;
            */

            const { data : stakingList } = await httpClient.get(API_HOST('/__authorize/stake'));
            console.log(stakingList)
            this.list = stakingList.data;

            const { data : stakingInterestList } = await httpClient.get(API_HOST('/__authorize/stake/interest'));
            console.log(stakingList)
            this.interesetList = stakingInterestList.data;

            if(data.success == true){
                alert("Staking Success");
                this.amount = 0;
                this.receive = 0;
            } else {
                alert(`Staking Failed\nReason : ${data.message}`);
            }
        },
        async putAmountToMaximum() {
            const amount = parseFloat(this.currencies['dece'].balance)
            this.amount = (amount - (amount % 100));
            /*
            const c3p_bal = (parseFloat(this.currencies['c3p'].balance) * 1 / 1);
            const c3p_price = (parseFloat(this.currencies['c3p'].price) * 1 / 1);
            this.c3p_price = c3p_price;
            console.log(this.daily_limit, c3p_bal, c3p_price)

            if(this.daily_limit < c3p_bal * c3p_price) {
                this.amount = this.daily_limit
            //    this.amount = Math.floor(this.daily_limit / c3p_price * 0.999 * 10 ** 4) / 10 ** 4;
            //} else if(c3p_bal * c3p_price > 100) {
            //    this.amount = 100 / c3p_price;
            } else {
                this.amount = c3p_bal;
            }
            */
        },
        getDateFormatted(dateStr){
            try {
                let moment = this.$moment(dateStr);
                return moment.format('YYYY-MM-DD HH:mm:ss');
            } catch (e) {
                // do nothing
            }

            // cannot process anything: return raw;
            return dateStr;
        },

        getDateOnlyFormatted(dateStr){
            try {
                let moment = this.$moment(dateStr);
                return moment.format('YYYY-MM-DD');
            } catch (e) {
                // do nothing
            }
            // cannot process anything: return raw;
            return dateStr;
        },
        calcDisplayAmount(amount, fractionDigits) {
            return toFixedNumberAndLocaleString(amount, fractionDigits)
        },
    },
    watch: {
        amount : function(val){
            //this.receive = (Math.floor((val * this.currencies['c3p'].price / this.currencies['fr'].price) * 10 ** 2) / 10 ** 2)?.toFixed(2);
            this.amount_dece = (val/this.currencies['dece'].price*this.currencies['bnb'].price)?.toFixed(2);
            this.amount_c3p = (val/this.currencies['c3p'].price*this.currencies['bnb'].price)?.toFixed(2);
        },
        /*
        amount_dece : function(val){
            //this.receive = (Math.floor((val * this.currencies['c3p'].price / this.currencies['fr'].price) * 10 ** 2) / 10 ** 2)?.toFixed(2);
            // this.amount = (val*this.currencies['dece'].price/this.currencies['bnb'].price)?.toFixed(2);
            //this.amount_dece = (val*this.currencies['dece'].price)?.toFixed(2);
            //this.amount_c3p = (val*this.currencies['c3p'].price)?.toFixed(2);
        },
        amount_c3p : function(val){
            //this.receive = (Math.floor((val * this.currencies['c3p'].price / this.currencies['fr'].price) * 10 ** 2) / 10 ** 2)?.toFixed(2);
            // this.amount = (val*this.currencies['c3p'].price/this.currencies['bnb'].price)?.toFixed(2);
            //this.amount_dece = (val*this.currencies['dece'].price)?.toFixed(2);
            //this.amount_c3p = (val*this.currencies['c3p'].price)?.toFixed(2);
        },
        */
    }
}
</script>

<style lang="scss" scoped>
@import '../const';

header:not(.app-bar) {
    position: relative;

    pointer-events: none;
    div.balance-area {
        position: absolute;
        left: 0; top: 0;
        width: 100%;
    }
}

.balance-padding-lr {
    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: bold;
}

.cryptocurrency-list {
    background-color: $BASE_COLOR;
    .cryptocurrency {
        align-items: flex-start;
        div {
            flex: 1;
        }
        .info-container{
        display: flex; 
        gap:8px;
        align-items: flex-start;
        }
        .graph-container{
            align-self: end;
        }
    }
}

div.container {
    display: flex;
    padding: 10px 16px 24px;

    button {
        flex: 1;
    }
}

.setting-time-buttons table tr td button{
    background-color: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;
    color: #acacac;
    
    &:focus{
        border: 1px solid $BASE_SUB_COLOR_BRIGHTER;
        color: $BASE_SUB_COLOR_BRIGHTER;
    }
}

.btn-area {
    display: flex;
    padding: 0 16px;
    margin-bottom: 30px;
    & > * {
        flex: 1;
        &:first-child { margin-right: 8px; }
        &:last-child  { margin-left: 8px;  }
        button { width: 100%; }
    }
}

.transaction-history-title {
    margin-top: 35px;
    text-align: center;
    color: $BASE_SUB_COLOR_BRIGHTER;
    font-size: 18px;
    font-weight: 900;
}

.transaction-list {
    // padding: 8px;

    .transaction {
        // each transaction history
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        .txn-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 80%;

            .txn-ico-area {
                // only 1 icon
                margin-right: 15px;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }

            .txn-info-area {
                // refer .txn-ico-area
                max-width: calc(100%);

                display: flex;
                flex-direction: column;
                justify-content: left;

                font-size: 14px;

                .txn-info-txid {
                    max-width: 100%;

                    font-family: monospace;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .txn-right {
            //amount and unit

            max-width: 39%;
            font-size: 14px;

            text-align: right;

            .txn-amount {
                font-weight: bold;
            }
            .txn-unit {
                opacity: 0.6;
            }
        }
    }
}

.unemphasis {
    opacity: 0.6;
    margin-top: 10px;
}

.chart-area {
    padding: 0 16px;
    margin-bottom: 15px;

    canvas {
        max-height: 180px !important;
        width: calc(100% - (16px * 1)) !important;
    }
}

.direction-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.min-value-area {
    margin-top: 8px;
}


div.input-entity {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin-bottom: 20px;

    label {
        font-size: 14px;
        line-height: 1.33;
        font-weight: bold;
        color: $TEXT_COLOR;

        margin-bottom: 6px;
    }

    input {
        border: 0;

        height: 56px;

        box-sizing: border-box;

        transition: all 0.3s;

        color: $BASE_COLOR;
        padding: 16px;
        font-size: 16px;
        line-height: 1.31;

        outline: 0;

        padding-right: 56px; //+16px;
        background-color: $BASE_COLOR;
        border: 1px solid $BORDER_COLOR;
        color:$TEXT_COLOR;
        &::placeholder,
        &::-webkit-input-placeholder {
        color: rgba($TEXT_COLOR, 0.87);
        opacity: 0.5;
        }

        &:focus {
            border-bottom: 2px solid $BASE_COLOR;
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: rgba($BASE_COLOR, 0.87);
            opacity: 0.5;
        }
        &.white{
            background-color: white;
            border-bottom: none;
        }
        &.black{
            background-color: $BASE_BACK_COLOR;
            border: 1px solid $BORDER_COLOR;
            color: white;
            &::placeholder,
            &::-webkit-input-placeholder {
                color: rgba($TEXT_COLOR, 0.87);
                opacity: 0.5;
            }
        }
    }

    &.input-email{
        input {
            width: 100%;
            background-color: $BASE_COLOR;
            border: 1px solid $BORDER_COLOR;
            color:$TEXT_COLOR;
            &::placeholder,
            &::-webkit-input-placeholder {
            color: rgba($TEXT_COLOR, 0.87);
            opacity: 0.5;
            }
        }
    }

    &.change-profile {
        padding-top: 30px;

        input.change-input {
            width: 100%;
            margin-bottom: 20px;
            background-color: $BASE_COLOR;
            border: 1px solid $BORDER_COLOR;
            color:$TEXT_COLOR;
            &::placeholder,
            &::-webkit-input-placeholder {
            color: rgba($TEXT_COLOR, 0.87);
            opacity: 0.5;
            }
        }

        label {
            font-size: 12px;
            line-height: 1.33;
            font-weight: bold;
            color: $TEXT_COLOR;
            display: block;
            margin-bottom: 4px;
        }
    }

    &.watchable-password {
        div.input-area {
            position: relative;

            input.input-password {
                width: 100%;
                padding-right: 56px; //+16px;
                background-color: $BASE_COLOR;
                border: 1px solid $BORDER_COLOR;
                color:$TEXT_COLOR;
                &::placeholder,
                &::-webkit-input-placeholder {
                color: rgba($TEXT_COLOR, 0.87);
                opacity: 0.5;
                }
            }

            button.open-input-password {
                position:absolute;
                right: 0;
                top: 0;

                background: transparent;
                box-shadow: none;

                width: 56px;
                height: 56px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.scannable-qr-code {
        div.input-area {
            position: relative;
            input.input-address {
                width: 100%;
                padding-right: 56px;//+16px;
            }
            button.scan-qr-code {
                position:absolute;
                right: 0;
                top: 0;

                background: transparent;
                box-shadow: none;

                width: 56px;
                height: 56px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@media (max-width: 350px) {
	div.input-entity {
		width: 65vw;
	}
	div.input-entity .btnArea {
		display: flex;
	}
	div.input-entity .btnArea input {
		width: 0%;
		flex-grow: 1;
	}
}

</style>