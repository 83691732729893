import Vue from 'vue';
import Vuex from 'vuex';

import httpClient, { API_HOST } from "../httpClient";


Vue.use(Vuex);

const getDefaultState = () => {
    return {
        isQRScanning: false,
        isNavigationMenuVisible: false,
        isApplicationReady: false,
        isLoggedIn: null,
        profile: null,
        rates: {
            c: 1,
            DECE: 1,
            BNB: 1,
        },
        currencies: {
            bnb: {
                displayName: '바이낸스 스마트체인',
                symbol: 'BNB',
                address: '',
                balance: 0,
                price: 200,
                backgroundColor: '#fff',
                icon: require('../assets/bsc.svg'),
                isErc20: true,
                isLockable: true,
                locked: 0,
                fee: 0.005,
                transactionFee: 0.005,
                fractionDigits: 6,
                reliesOnTron: true,
            },
            dece: {
                displayName: '데시',
                symbol: 'DECE',
                address: '',
                balance: 0,
                price: 1,
                backgroundColor: '#fff',
                icon: require('../assets/dece.svg'),
                isErc20: true,
                isLockable: true,
                locked: 0,
                fee: 0.3,
                transactionFee: 0.3,
                fractionDigits: 6,
                reliesOnTron: false,
            },
            c3p: {
                displayName: '코스모스3 페이',
                symbol: 'C3P',
                address: '',
                balance: 0,
                price: 1,
                backgroundColor: '#fff',
                icon: require('../assets/logo.png'),
                isErc20: true,
                isLockable: true,
                locked: 0,
                fee: 0.3,
                transactionFee: 0.3,
                fractionDigits: 6,
                reliesOnTron: false,
            },
        }
    }
};

//initial state
const state = getDefaultState();

const getters = {
    isQRScanning: state => state.isQRScanning,
    isNavigationMenuVisible: state => state.isNavigationMenuVisible,

    isApplicationReady: state => state.isApplicationReady,
    isLoggedIn: state => state.isLoggedIn,
    profile: state => state.profile,
    currencies: state => state.currencies,
    rates: state => state.rates,
};

const mutations = {
    setQRScanning: (state, isQRScanning) => {
        try {
            if (state.isQRScanning && !isQRScanning) {
                // currently QR Scanning. but wants to be turned off.

                // hide now!;
                window.QRScanner.hide();
                // and cancel current Scan now!;
                window.QRScanner.cancelScan();

                // and destroy. when user clicks scan button it does prepare again;
                window.QRScanner.destroy();
            }
        } finally {
            state.isQRScanning = isQRScanning;
        }
    },
    setProfile: (state, profile) => state.profile = profile,
    setNavigationMenuVisible: (state, isNavigationMenuVisible) => state.isNavigationMenuVisible = isNavigationMenuVisible,
    setLoggedIn: (state, isLoggedIn) => state.isLoggedIn = isLoggedIn,
    setApplicationReady: (state, isApplicationReady) => state.isApplicationReady = isApplicationReady,
    resetState(state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },
};

const actions = {
    refreshRate: async (context) => {
        const currencies = context.getters.currencies;
        const rates = context.getters.rates;
        const { data } = await httpClient.get(API_HOST('/api/system'));

        Object.keys(rates).forEach((key) => {
            let rate = data[`${key.toUpperCase()}_price`];
            if (rate && !Number.isNaN(rate)) {
                rates[`${key}`] = rate;
                currencies[`${key.toLowerCase()}`]['price'] = rate;
                console.log(key, rate)
            }
        });
    },

    // eslint-disable-next-line no-unused-vars
    refreshDashboard: async (context) => {
        const currencies = context.getters.currencies;
        Object.keys(currencies).forEach((key) => {
            httpClient.get(API_HOST(`/api/${key}/balance`));
        });
        const { data } = await httpClient.get(API_HOST('/__authorize/profile'));
        context.commit('setProfile', data);

        Object.keys(currencies).forEach((key) => {
            currencies[`${key}`] = {
                ...currencies[`${key}`],
                ...data[`${key}`]
            };
        });
    },

    /**
     * 로그인을 행합니다.
     */
    // eslint-disable-next-line no-unused-vars
    login: async (context, { email, password }) => {
        try {
            const { data } = await httpClient.post(API_HOST('/__authorize/login'), {
                email, password
            });

            localStorage.__token = data.token;

            context.commit('setLoggedIn', true);
        } catch (e) {
            const { reason } = e.response.data;
            alert(`failed to sign in.\n${reason}`);
            throw e;
        }
    },
    confirmPassword: async (context, { email, password }) => {
        try {
            await httpClient.post(API_HOST('/__authorize/login'), {
                email, password
            });

        } catch (e) {
            alert(`Password is wrong `);
            throw e;
        }
    },

    register: async (context, { email, password, phone, name, referrer }) => {
        try {
            await httpClient.post(API_HOST('/__authorize/register'), {
                email, password, phone, name, referrer
            });
            window.alert('verification mail sent.\ncheck your e-mail.');
        } catch (e) {
            const { message } = e.response.data;
            window.alert(message);
        }
    },

    ping: async (context) => {
        try {
            await context.dispatch('refreshDashboard');
            context.commit('setLoggedIn', true);
        } catch (e) {
            console.error('heartbeat failed; logging out');
            context.commit('setLoggedIn', false);
        } finally {
            context.commit('setApplicationReady', true);
        }
    },

    // resets state
    resetStoredStates({ commit }) {
        localStorage.clear();
        commit('resetState')
    },

    startQRScanning: async (context, { successCallback }) => {
        let isQRScanning = context.getters.isQRScanning;

        if (!isQRScanning) {
            // QRSCanner is not running
            // start QRScanner;

            const handleQRScannerError = function (err) {
                if (err && err.name && typeof (err.name) === 'string') {
                    switch (err.name) {
                        case 'UNEXPECTED_ERROR':
                            window.plugins.toast.show(
                                'QR code scanner caught unexpected error', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                        case 'CAMERA_ACCESS_DENIED':
                            window.plugins.toast.show(
                                'To scan QR codes, please allow camera permission.', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            leadUserAllowCameraPermission(true);
                            break;
                        case 'CAMERA_ACCESS_RESTRICTED':
                            window.plugins.toast.show(
                                'Access to camera is restricted. This may have been set by Mobile Device Management, Parental controls and other settings', // message
                                'long', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                        case 'BACK_CAMERA_UNAVAILABLE':
                        //break;
                        // eslint-disable-next-line no-fallthrough
                        case 'FRONT_CAMERA_UNAVAILABLE':
                        //break;
                        // eslint-disable-next-line no-fallthrough
                        case 'CAMERA_UNAVAILABLE':
                            window.plugins.toast.show(
                                'Camera unavailable.', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                        case 'SCAN_CANCELED':
                            window.plugins.toast.show(
                                'scanning cancelled', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                        case 'LIGHT_UNAVAILABLE':
                            window.plugins.toast.show(
                                'flashlight unavailable.', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                        case 'OPEN_SETTINGS_UNAVAILABLE':
                            window.plugins.toast.show(
                                'Could not open settings to allow use of camera', // message
                                'short', // toast length
                                'bottom', // position
                                function (a) { // toast success callback
                                    console.log('toast success: ' + a)
                                },
                                function (b) { // toast error callback
                                    alert('toast error: ' + b)
                                },
                            );
                            break;
                    }
                }
            };

            // eslint-disable-next-line no-unused-vars
            const leadUserAllowCameraPermission = function (isFirstRun) {
                // let openSettings = true;
                // if (isFirstRun){
                //     openSettings = confirm('To scan QR codes, please click OK and allow camera permission.');
                //
                //     if (openSettings) {
                //         // if not same window.QRScanner.openSettings() called twice. it won't gets open at first click;
                //         leadUserAllowCameraPermission(false);
                //     }
                // }
                // if (openSettings) {
                //     window.QRScanner.openSettings();
                // }
                navigator.notification.confirm(
                    'To scan QR codes, please click "Go to Settings" and allow camera permission.',
                    (indexOfButtonPressed) => {
                        if (indexOfButtonPressed === 1) { // positive
                            openQRScannerSettings();
                        } else if (indexOfButtonPressed === 2) { // negative
                            // do nothing
                        } else if (indexOfButtonPressed === 3) { // neutral?
                            // do nothing
                        } else { // dismissed without button press
                            // do nothing
                        }
                    },
                    'Please allow using camera',
                    ['Go to Settings', 'Cancel']
                );
            };

            const openQRScannerSettings = function () {
                window.QRScanner.openSettings();
            };

            const onPrepareDone = async function (err, status) {
                // https://www.npmjs.com/package/cordova-plugin-qrscanner

                if (err) {
                    // here we can handle errors and clean up any loose ends.
                    console.error(err);
                    handleQRScannerError(err);

                    return false;
                }
                if (status.authorized) {
                    // W00t, you have camera access and the scanner is initialized.
                    // QRscanner.show() should feel very fast.


                    context.commit('setQRScanning', true);

                    try {
                        window.QRScanner.show();
                        const [err, text] = await new Promise((resolve) => window.QRScanner.scan((err, text) => resolve([err, text])));
                        if (err) {
                            console.error(err);
                            handleQRScannerError(err);
                        } else {
                            if (successCallback && typeof (successCallback) === 'function') {
                                successCallback(text);
                            }
                        }
                    } finally {
                        context.commit('setQRScanning', false);
                    }

                } else if (status.denied) {
                    // The video preview will remain black, and scanning is disabled. We can
                    // try to ask the user to change their mind, but we'll have to send them
                    // to their device settings with `QRScanner.openSettings()`.

                    leadUserAllowCameraPermission(true);
                } else {
                    // we didn't get permission, but we didn't get permanently denied. (On
                    // Android, a denial isn't permanent unless the user checks the "Don't
                    // ask again" box.) We can ask again at the next relevant opportunity.

                    window.plugins.toast.show(
                        'To scan QR codes, please allow camera permission.', // message
                        'short', // toast length
                        'bottom', // position
                        function (a) { // toast success callback
                            console.log('toast success: ' + a)
                        },
                        function (b) { // toast error callback
                            alert('toast error: ' + b)
                        },
                    );
                }
            };

            try {
                if (window.QRScanner) {
                    window.QRScanner.prepare(onPrepareDone);
                }
            } finally {
                // do nothing;
                // window.QRScanner will be available all times when deployed;
            }
        }
    },
};

const modules = {};

export default new Vuex.Store({
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: modules,
});
