import Vue from 'vue';
import VueMoment from 'vue-moment';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueMoment);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#mounted-app');
