<template>
    <div class="page">
        <appbar can-navigate-back is-navigation-disabled />
        <div class="contentArea">
            <div class="logoArea">
                <img class="logoImg" :src="require('../assets/logo.png')"/>
                <span>Sign Up</span>
            </div>

            <div class="input-entity">
                <label>Email</label>
                <input class="black" type="email" v-model="email" placeholder="Enter your Email (Gmail recommended)" />
				<!-- <p class="warn">※ NAVER, NATE, YAHOO mail cannot be used.</p> -->
            </div>

            <div class="input-entity bottom-area watchable-password">
                <label>Password</label>
                <div class="input-area">
                    <input :type="passwordInputType" class="input-password" v-model="password" placeholder="Enter your password" />
                    <button class="button open-input-password" @click.prevent="passwordIsOpen = !passwordIsOpen">
                        <img width="auto" v-if="passwordIsOpen" src="../assets/input/ico_visibility-on.svg">
                        <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">
                    </button>
                </div>
            </div>

            <div class="input-entity bottom-area watchable-password">
                <label>Password Retype</label>
                <div class="input-area">
                    <input :type="passwordReInputType" class="input-password" v-model="password_re" placeholder="Enter your password again" />
                    <button class="button open-input-password" @click.prevent="passwordReIsOpen = !passwordReIsOpen">
                        <img width="auto" v-if="passwordReIsOpen" src="../assets/input/ico_visibility-on.svg">
                        <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">
                    </button>
                </div>
            </div>

            <div class="input-entity">
                <label>Referral</label>
                <input v-model="referrer" class="black" placeholder="(Required) type your referrer code" />
            </div>

            <div class="button-area">
                <button class="button positive font-size-positive" @click="performSignUp">Sign up</button>
            </div>
        </div>
    </div>
</template>

<script>
import httpClient, { API_HOST } from "../httpClient";
import Appbar from '../components/Appbar';

export default {
    components: { Appbar },
    data() {
        return {
            email: '',
            password: '',
            password_re: '',
            name: '',
            phone: '',
            passwordIsOpen: false,
            passwordReIsOpen: false,
            referrer: '',
        };
    },

    computed: {
        passwordInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.passwordIsOpen);
            },
        },

        passwordReInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.passwordReIsOpen);
            },
        },
    },

    methods: {
        async performSignUp() {

            this.email = this.email.trim();
            if (!this.email) {
                alert('check your email');
                return false;
            }

            if ( ! /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.exec(this.email)) {
                alert('email address seems not correct');
                return false;
            }

            if ((!this.password || !this.password_re) || this.password !== this.password_re) {
                alert('check your password!');
                return false;
            }

            if(!this.referrer) {
                alert('check your referrer!');
                return false;
            }

            const { data } = await httpClient.post(API_HOST('/__authorize/check-refferer'), {
                referrer: this.referrer
            });

            console.log(data)
            if(data.result == false) {
                alert('referrer doest not exist!');
                return false;
            }

            const { email, password, phone, name, referrer } = this;
            await this.$store.dispatch('register', { email, password, phone, name, referrer });
            this.$router.replace('/signin');
        },

        getTypeOfInputForPassword(isOpen) {
            if (isOpen) {
                return "text"
            } else {
                return "password";
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.page {
    .contentArea {
        display: flex;
        flex-direction: column;

        .logoArea {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 50px 0 30px;

            .logoImg {
                width: 98px;
                height: 98px;
            }
        }
		.input-entity {
			.warn {
				margin-top: .25rem;
				margin-left: 1rem;
				margin-bottom: 0;
			}
		}
        span {
            margin-top: 20px;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
        }
    }

    .button-area {
        padding: 0 16px;
        margin-top: 10px;
    }
}
</style>
