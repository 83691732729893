<template>
    <div class="home has-footer-navbar">
        <Appbar />
        <div class="circle-container">
            <div class="total-balance">
                <img src="../assets/main-logo.png" width="120px" height="120px"/>
                <span class="balance_in_usd" :style="`font-size: ${totalBalanceFontSize}px;`">Total {{ totalConvertedBalance }} C3P</span>
            </div>
        </div>
        <router-link class="cryptocurrency-item" v-for="(currency, currencyId) in $store.state.currencies" :key="currencyId" :to="`/${currencyId}/balance`">
            <div class="cryptocurrency-list">
                <div class="cryptocurrency">
                    <div class="info-container">
                        <div style="padding:8px">
                            <div class="currency-area">
                                <div class="currency-name">{{ currency.displayName }}</div>
                                <div class="currency-id">{{ currencyId.toLocaleUpperCase() }}</div>
                            </div>
                            <div class="currency-balance">
                                {{
                                    (Math.floor(parseFloat(currency.balance) * (10 ** 6)) / (10 ** 6))?.toFixed(6)
                                }} {{ currencyId.toLocaleUpperCase() }}
                                ≈ {{
                                    (Math.floor((parseFloat(currency.balance) * parseFloat(currency.price)) * (10 ** 2)) / (10 ** 2))?.toFixed(2)
                                }} C3P
                            </div>
                        </div>
                    </div>
                    <div class="current-currency">
                        <aside><img :src="currency.icon"/></aside>
                    </div>
                </div>
            </div>
        </router-link>
        <router-link class="cryptocurrency-item" key="stake" :to="`/stake`">
            <div class="cryptocurrency-list cosmos3paymall">
                <div class="cryptocurrency">
                    <div class="info-container">
                        <div style="padding:8px">
                            <div class="currency-area">
                                <div class="currency-name">DECE DAO</div>
                            </div>
                        </div>
                    </div>
                    <div class="current-currency">
                        <aside><img :src="require('../assets/decedao.svg')"/></aside>
                    </div>
                </div>
            </div>
        </router-link>
        <a class="cryptocurrency-item" href="http://cosmos3mall.com/" target="_blank">
            <div class="cryptocurrency-list cosmos3paymall">
                <div class="cryptocurrency">
                    <div class="info-container">
                        <div style="padding:8px">
                            <div class="currency-area">
                                <div class="currency-name">COSMOS3 Mall</div>
                            </div>
                        </div>
                    </div>
                    <div class="current-currency">
                    </div>
                </div>
            </div>
        </a>
        <FooterNavbar />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Appbar from '../components/Appbar';
import FooterNavbar from "../components/FooterNavbar";
import {toFixedNumberAndLocaleString} from "../components/utils";

export default {
    components: {
        FooterNavbar,
        Appbar,
    },
    data() {
        return {
            dataFecther: null,
            transactions: [],
            datacollection: {},
            interval: '1h',
            duration: '24h',
            message: null,
            confirm_result: 'N',

            options: {
                animation: {
                    duration: 1, // disable animation
                },
                legend: { //hides the legend
                    display: false,
                },
                scales: { //hides the y axis
                    yAxes: [{
                        display: false,
                        ticks: {
                            fontSize: 15,
                            maxTicksLimit: 1,
                            fontColor: 'white',
                            callback: function(value) {
                                return '$' + toFixedNumberAndLocaleString(value, 2);
                            },
                        },
                        gridLines: {
                            color: "rgb(95,169,85)",
                        },
                    }],
                    xAxes: [{
                        display: false
                    }]
                }
            },
            graphRange:{
                min: null,
                max: null,
            },
            DonationFr: 0,
            DonationDollar: 0,
        }
    },
    computed: {
        ...mapGetters(['currencies', 'rates']),
        totalConvertedBalance() {
            return toFixedNumberAndLocaleString(Object.keys(this.currencies).reduce(
                (prevVal, key) => {
                    // console.log(key)
                    //if(key !== "bnb") {return (prevVal + (this.currencies[key].balance * this.currencies[key].price))}
                    //else {return prevVal}
                    return (prevVal + (this.currencies[key].balance * this.currencies[key].price))
                },
                0), 2);
        },
        totalBalanceFontSize() {
            let total_length = this.totalConvertedBalance.length;
            let font_size = 32;

            if (total_length >= 6) {

                while ((font_size / 2 * total_length) > 100) {
                    font_size = font_size - (font_size / (2 * total_length));
                }
            }

            return font_size;
        },
    },
    async mounted() {
        this.dataFecther = setInterval(this.fetchTxHistory, 3000);
        this.$store.dispatch('refreshDashboard');
        await this.$store.dispatch('refreshRate');
        //this.filldata();
    },
    unmounted() {
        clearInterval(this.dataFecther)
    },
    methods: {
        balanceAmountForDisplay(balance, fractionDigits) {
            return toFixedNumberAndLocaleString(balance, fractionDigits);
        },
        balancePriceForDisplay(balance, currencyId) {
            return toFixedNumberAndLocaleString(balance * this.rates[`${currencyId}`], 2);
        },
        openBtcExplorer(txid) {
            window.open(`https://www.blockchain.com/explorer/transactions/btc/${txid}`, '_self ', 'location=yes');
        },
        openStxExplorer(txid) {
            window.open(`https://explorer.hiro.so/txid/${txid}`, '_self ', 'location=yes');
        },
    },
}
</script>

<style lang="scss" src="./Home.scss" />
