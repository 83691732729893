<template>
    <div class="template">
        <Appbar title="My Profile" />
        <div class="section-wrapper" v-if="profile">
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-email.svg')" />
                </aside>
                <div>
                    <h3>Email</h3>
                    <p>{{ profile.email }}</p>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-ping.svg')" />
                </aside>
                <div>
                    <h3>My Address</h3>
                    <p>{{ profile.bnb.address }}</p>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>Referral code</h3>
                    <p>{{ profile.referral_code }}</p>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>Referee</h3>
                    <p>{{ profile?.referee }} ({{ profile?.referee_horizontal }})</p>
                </div>
            </section>
            <section v-if="Number(profile.emoji) >= 1 ? true : false">
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>Grade</h3>
                    <p v-html="this.emoji"></p>
                </div>
            </section>
            <div class="container" style="display: flex; padding: 0 0 24px;">
                <button style="flex: 1; margin-right: 16px; font-size: 14px;" class="button positive" @click="logout">Logout
                </button>
                <!-- <button style="flex: 1; margin-left: 8px;"  class="button" @click="exportPK">Export PK and delete account</button> -->

                <router-link to="/editprofile" style="flex:1">
                    <button style="font-size: 14px;" class="button positive">Edit Profile</button>
                </router-link>
            </div>
        </div>
        <FooterNavbar />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FooterNavbar from "../components/FooterNavbar";
import Appbar from "../components/Appbar";

export default {
    data(){
        return {
            emoji: '&#9728;&#65039;'
        }
    },
    components: {Appbar, FooterNavbar},
    computed: {
        ...mapGetters(['profile']),
        isPasscodeSet() {
            return localStorage.getItem("__passcode") !== null;
        },
    },
    created() {
        this.emojiCheck(this.profile.emoji);
    },
    methods: {
        logout() {
            this.$store.dispatch('resetStoredStates');
        },
        setPasscode() {
            if(this.isPasscodeSet) {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'update', redirectTo: '/' },
                });
            } else {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'register', redirectTo: '/' },
                });
            }
            this.$store.commit('setNavigationMenuVisible', false);
        },
        emojiCheck(emoji) {
            if(emoji === "1"){
                this.emoji = '&#9728;&#65039;'
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../const';

div.section-wrapper {
    padding: 30px 16px;
    margin-bottom: 2em;
}

section {
    padding: 25px;
    margin-bottom: 20px;

    display: flex;
    background: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;


    aside.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        width: 20px
    }

    h3 {
        font-weight: normal;
        color: rgba($TEXT_COLOR, 0.6);
        font-size: 14px;
    }

    p {
        padding: 0;
        margin: 0;

        word-break: break-all;
    }


}
</style>
