import Axios from 'axios';

const httpClient = Axios.create({
    transformRequest: [
        function(data, headers) {
            if (localStorage.__token) {
                headers.authorization = localStorage.__token;
            }

            return data;
        },
        ...Axios.defaults.transformRequest
    ]
});

export default httpClient;
export const API_HOST = (path) => `${process.env.VUE_APP_API_URL}${path}`;
