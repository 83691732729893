<template>
    <div class="passcode">
        <Appbar :title="mode === 'input' ? 'Input PIN Code' : (isUpdate ? 'Input PIN Code' : (isRecheck ? 'Input New PIN Again' : 'Input New PIN Code'))" is-navigation-disabled />

        <header id="passcode-header">
            <div v-for="(i, index) in Array.of(...new Array(4))" :key="index" :class="[input.length - 1 >= index ? 'input' : '']"></div>
        </header>

        <div class="keypad">
            <div class="row">
                <button @touchend.stop.prevent="input += '1'">1</button>
                <button @touchend.stop.prevent="input += '2'">2</button>
                <button @touchend.stop.prevent="input += '3'">3</button>
            </div>
            <div class="row">
                <button @touchend.stop.prevent="input += '4'">4</button>
                <button @touchend.stop.prevent="input += '5'">5</button>
                <button @touchend.stop.prevent="input += '6'">6</button>
            </div>
            <div class="row">
                <button @touchend.stop.prevent="input += '7'">7</button>
                <button @touchend.stop.prevent="input += '8'">8</button>
                <button @touchend.stop.prevent="input += '9'">9</button>
            </div>
            <div class="row">
                <button @touchend.stop.prevent="backspace">←</button>
                <button @touchend.stop.prevent="input += '0'">0</button>
                <button></button>
            </div>
        </div>
    </div>
</template>

<script>
import Appbar from '../components/Appbar';

export default {
    name: 'Passcode',
    props: {
        mode: { type: String, default: 'input' },
        redirectTo: { type: String, default: '/' },
    },
    components: { Appbar },
    computed: {
        passcode() {
            return localStorage.getItem('__passcode');
        }
    },
    watch: {
        input: {
            handler(newVal) {
                // 리팩터링 필요. 난 몰라~~
                if (newVal.length === 4) {
                    if (this.mode === 'register' && !this.isRecheck) {
                        this.tmp = this.input
                        this.input = '';
                        this.isRecheck = true;
                        this.isUpdate = false;
                    } else if (this.mode === 'register' && this.isRecheck && this.input === this.tmp) {
                        alert(`Passcode registered!\nNew passcode is ${this.input}`);
                        localStorage.setItem('__passcode', this.input);
                        this.$router.replace(this.redirectTo);
                    } else if (this.mode === 'register' && this.isRecheck && this.input !== this.tmp) {
                        this.input = '';
                        this.isRecheck = false;
                        this.isUpdate = true;
                        alert("Check your pin code");
                    } else if (this.mode === 'update') {
                        if (newVal === this.passcode && this.isUpdate) {
                            this.input = '';
                            this.isUpdate = false;
                        } else if (newVal !== this.passcode && this.isUpdate) {
                            this.input = '';
                            alert("Check your pin code");
                        } else if (!this.isUpdate && !this.isRecheck) {
                            this.tmp = this.input
                            this.input = '';
                            this.isRecheck = true;
                        } else if (!this.isUpdate && this.isRecheck && this.input === this.tmp) {
                            alert(`Passcode Changed!\nNew passcode is ${this.input}`);
                            localStorage.setItem('__passcode', this.input);
                            this.$router.replace(this.redirectTo);
                        } else if (!this.isUpdate && this.isRecheck && this.input !== this.tmp) {
                            this.input = '';
                            this.isRecheck = false;
                            alert("Check your pin code");
                        } else {
                            alert("Something Went Wrong");
                        }
                    } else {
                        if (newVal === this.passcode) {
                            this.$router.replace(this.redirectTo);
                        } else {
                            this.input = '';
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            input: '',
            isUpdate: true,
            isRecheck: false
        }
    },
    methods: {
        backspace() {
            if (this.input.length) {
                this.input = this.input.substring(0, this.input.length - 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../_const.scss';

header#passcode-header {
    padding: 2em;
    padding-top: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        width: 24px; height: 24px;
        background: rgba($BASE_COLOR, 0.3);

        border-radius: 50%;
        margin: 0.5em;

        transition: background-color 0.3s;

        &.input {
            background-color: $BASE_COLOR;
        }
    }
}
.passcode {
    .keypad {
        position: fixed;
        left: 0; bottom: 0;
        width: 100vw;

        .row {
            display: flex;
            & > * {
                flex: 1 0;
                height: 96px;

                font-size: 2em;
                padding: 0;

                background-color: transparent;
                color: $TEXT_COLOR;
                border: 0;

                box-sizing: border-box;
                user-select: none;
            }
        }
    }
}
</style>
