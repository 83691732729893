<template>
    <footer class="navbar">
        <!--
        <router-link to="/home">
            <img :src="require('../assets/icon-nav1.svg')"/>
        </router-link>
        -->
        <router-link to="/home">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_107" data-name="그룹 107" transform="translate(-105)">
                    <path id="패스_47" data-name="패스 47" d="M23.879,8.215V4.858A3.358,3.358,0,0,0,20.521,1.5H4.972a3.471,3.471,0,1,0,0,6.942h18.9A3.358,3.358,0,0,1,27.229,11.8V24.713a3.358,3.358,0,0,1-3.358,3.358H4.859A3.358,3.358,0,0,1,1.5,24.713V4.972" transform="translate(136 14)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>
        </router-link>
        <router-link to="/swap">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_109" data-name="그룹 109" transform="translate(-195)">
                    <g id="그룹_105" data-name="그룹 105" transform="translate(115.649 -37.915)">
                    <ellipse id="타원_11" data-name="타원 11" cx="6.793" cy="6.793" rx="6.793" ry="6.793" transform="translate(109.351 52.915)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <rect id="사각형_31" data-name="사각형 31" width="13.884" height="13.884" rx="3" transform="translate(125.39 68.116)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="패스_45" data-name="패스 45" d="M34.133,11.918,30.015,7.8l4.118-4.118" transform="translate(98.487 50.698)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="선_12" data-name="선 12" x2="10.21" transform="translate(128.502 58.498)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="패스_46" data-name="패스 46" d="M11.878,28.339,16,32.457l-4.118,4.118" transform="translate(104.443 43.157)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="선_13" data-name="선 13" x1="10.21" transform="translate(110.229 75.614)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </g>
                </svg>

        </router-link>
        <router-link to="/stake">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 439.2 439.2">
                <g id="_레이어_1" data-name="레이어 1">
                    <path class="cls-2" d="m219.6,13c27.9,0,54.95,5.46,80.41,16.23,24.6,10.41,46.7,25.3,65.67,44.28,18.98,18.98,33.88,41.07,44.28,65.68,10.77,25.46,16.23,52.52,16.23,80.41s-5.46,54.95-16.23,80.41c-10.41,24.6-25.3,46.7-44.28,65.67s-41.07,33.88-65.67,44.28c-25.46,10.77-52.52,16.23-80.41,16.23s-54.95-5.46-80.41-16.23c-24.6-10.41-46.7-25.3-65.68-44.28-18.98-18.98-33.88-41.07-44.28-65.67-10.77-25.46-16.23-52.52-16.23-80.41s5.46-54.95,16.23-80.41c10.41-24.6,25.3-46.7,44.28-65.68,18.98-18.98,41.07-33.88,65.68-44.28,25.46-10.77,52.52-16.23,80.41-16.23m0-13C98.32,0,0,98.32,0,219.6s98.32,219.6,219.6,219.6,219.6-98.32,219.6-219.6S340.89,0,219.6,0h0Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="cls-1" d="m35.72,127.43h67.57c68.1,0,51.91,111.74-67.57,102.94l33.78-80.24h20.06l-29.3,66.65c72.85-.4,75.99-71.33,39.24-71.33H31.23l4.49-18.02Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <polygon class="cls-1" points="152.03 127.43 152.03 230.86 221.71 230.86 221.71 213.12 170.33 213.12 170.33 187.08 215.73 187.08 215.73 169.13 170.33 169.13 170.33 145.55 221.71 145.55 221.71 127.43 152.03 127.43" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <polygon class="cls-1" points="328.7 127.43 328.7 230.86 398.38 230.86 398.38 213.12 347 213.12 347 187.08 392.4 187.08 392.4 169.13 347 169.13 347 145.55 398.38 145.55 398.38 127.43 328.7 127.43" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="cls-1" d="m301.6,159.98c-8.45-20.41-56.8-25.72-56.8,19.17s51.88,40.45,56.8,15.32l18.65,4.93c-20.76,57.36-96.74,34-96.74-18.98s65.42-78.85,96.74-26.41l-18.65,5.98Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="cls-1" d="m108.92,240.4h-52.09v89.74h52.09s48.21,1.23,48.21-44.87-48.21-44.87-48.21-44.87Zm-9.33,75.49h-22v-60.71h22s36.42,0,36.42,30.35-36.42,30.35-36.42,30.35Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="cls-1" d="m227.34,240.4h-19l-48.04,89.75h24.02l8.97-18.48h48.57l8.97,18.48h24.81l-48.3-89.75Zm-26.92,57.28l17.55-35.1,17.82,35.1h-35.37Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="cls-1" d="m337.81,238.02c-33.45,0-60.58,21.09-60.58,47.11s27.12,47.11,60.58,47.11,60.58-21.09,60.58-47.11-27.12-47.11-60.58-47.11Zm0,77.07c-21.27,0-38.52-13.41-38.52-29.96s17.24-29.96,38.52-29.96,38.52,13.41,38.52,29.96-17.24,29.96-38.52,29.96Z" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </svg>
        </router-link>
        <router-link to="/profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_110" data-name="그룹 110" transform="translate(-285)">
                    <g id="그룹_106" data-name="그룹 106" transform="translate(102.257 -38.258)">
                    <path id="패스_48" data-name="패스 48" d="M17.086,14.068a1.53,1.53,0,1,1-1.53-1.53,1.53,1.53,0,0,1,1.53,1.53" transform="translate(208.13 49.133)" fill="#acacac"/>
                    <path id="패스_49" data-name="패스 49" d="M28.332,14.068a1.53,1.53,0,1,1-1.53-1.53,1.53,1.53,0,0,1,1.53,1.53" transform="translate(204.436 49.133)" fill="#acacac"/>
                    <path id="패스_50" data-name="패스 50" d="M13.384,21.689a7.936,7.936,0,0,0,11.23,0" transform="translate(208.34 46.128)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <ellipse id="타원_12" data-name="타원 12" cx="13.565" cy="13.565" rx="13.565" ry="13.565" transform="translate(213.743 54.258)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </g>
            </svg>
        </router-link>
    </footer>
</template>

<script>
export default {
    name: 'FooterNavbar',

    methods: {
        popup() {
            alert("coming soon!")
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../const';

footer.navbar {
    position: fixed;
    left: 0; bottom: 0;
    width: 100%; height: 60px;

    background: $BASE_BACK_COLOR;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    display: flex;

    & > * {
        flex: 1 0;
        display: flex;
        align-items: center;
        justify-content: center;

        // opacity: 0.5;

        transition: opacity 0.3s;
        stroke: #acacac;
        &.router-link-exact-active {
            opacity: 1.0;
            stroke: #2196f3;
        }

        img {
            max-width: 60px;
            max-height: 60px;
        }
    }

}
</style>
