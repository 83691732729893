import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
      options: { required: true },
      range: { default: null },
  },
  mounted () {
      // this.chartData is created in the mixin.
      // If you want to pass options please create a local options object
      if (this.chartData && this.options) {
          this.renderChart(this.chartData, this.options);
      }
  },
  methods: {
      handleRangeChange(){
          let min = null;
          let max = null;
          if (this.range) {
              min = this.range.min;
              max = this.range.max;
          }
          this.$data._chart.options.scales.yAxes[0].ticks.min = min;
          this.$data._chart.options.scales.yAxes[0].ticks.max = max;
          this.$data._chart.update();
      },
  },
  watch: {
      range: {
          handler: 'handleRangeChange',
          immediate: true,
      },
  }
}
