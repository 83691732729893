<template>
    <div class="wallet-splash-view">
        <div class="wallet-splash-logo-outer">
            <img class="wallet-splash-logo" :src="require('../assets/logo.png')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "SplashScreen"
}
</script>

<style lang="css">
html, body {
    padding: 0; margin: 0;
}

div.wallet-splash-view {
    position: fixed;
    left: 0;
    top: 0;
    font-family: 'Roboto', sans-serif;
    background-image: url("../assets/background.png");
    background-size: cover;
    /*background: linear-gradient(to bottom, #202125 0%, #202125 100%);*/
    color: #202125;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    flex-direction: column;
}

div.wallet-splash-logo-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px; /* using raw dimension */
    height: 110px;
    background: transparent;
    border-radius: 50%;
}

img.wallet-splash-logo {
    display: block;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

.wallet-splash-title {
    margin-top: 20px;
    width: 160px;
    height: 22px;
    max-width: 100%;
}
</style>
