import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Balance from "../views/Balance";
import Send from '../views/Send';
import SignIn from "../views/SignIn";
import SignUp from '../views/SignUp';
import Receive from '../views/Receive';
import ConfirmSend from '../views/ConfirmSend';
import Profile from '../views/Profile';
import Stake from '../views/Stake';

import SplashScreen from '../views/SplashScreen';

import CompleteSend from '../views/CompleteSend';
import Passcode from '../views/Passcode';

import EditProfile from '../views/EditProfile';
import Swap from '../views/Swap';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/splash',
        component: SplashScreen,
        meta: {
            singleview: true,
        }
    },
    {
        path: '/:currency/balance',
        name: 'balance',
        component: Balance,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/send',
        name: 'send',
        component: Send,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/receive',
        name: 'receive',
        component: Receive,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/send/confirm',
        name: 'confirmSend',
        component: ConfirmSend,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/send/complete',
        name: 'completeSend',
        component: CompleteSend,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/:locked/balance',
        name: 'balance',
        component: Balance,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/:locked/send',
        name: 'send',
        component: Send,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/:locked/receive',
        name: 'receive',
        component: Receive,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/:locked/send/confirm',
        name: 'confirmSend',
        component: ConfirmSend,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/:currency/:locked/send/complete',
        name: 'completeSend',
        component: CompleteSend,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignUp
    },
    {
        path: '/passcode',
        name: 'passcode',
        component: Passcode,
        props: true,
    },
    {
        path: '/editprofile',
        name: 'EditProfile',
        component: EditProfile,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/swap',
        name: 'Swap',
        component: Swap,
        meta: {
            needsLogin: true,
        },
    },
    {
        path: '/stake',
        name: 'Stake',
        component: Stake,
        meta: {
            needsLogin: true,
        },
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
