<template>
    <div class="receive">
        <Appbar can-navigate-back :title="`Receive ${currentCurrency.displayName}`">
            <template v-slot:customizedTitle>
                Receive <b>{{currentCurrency.symbol}}</b>
            </template>
        </Appbar>
        <div class="circle-container" style="flex-direction: column;">
            <div class="receive-title">
                <p>Receive {{ currentCurrency.symbol }}</p>
                <img class="symbol" :src="currentCurrency.icon">
            </div>

            <div class="qr-area">
                <img class="no-padding" :src="qrDataUrl" v-if="qrDataUrl"/>
            </div>
        </div>
        <section class="address-area">
            <p style="text-align: left;">Your {{currentCurrencyId.toLocaleUpperCase()}} Address</p>
            <div class="elevated" @click="copyAddress" style="word-break: break-all;">{{ currentCurrency.address }}</div>
            <p>Tap <strong>{{ currentCurrency.symbol }}</strong> address to copy</p>
        </section>
    </div>
</template>

<script>
import qrcode from 'qrcode-generator';
import Appbar from '../components/Appbar';
import CurrencyPage from '../mixins/CurrencyPage';

/**
 * TODO: cordova-clipboard 등을 써서 복사 시켜야 함
 */
export default {
    name: 'Receive',
    components: { Appbar },
    mixins: [CurrencyPage],

    data() {
        return {
            qrDataUrl: null
        };
    },

    watch: {
        'currentCurrency.address': {
            immediate: true,
            handler() {
                this.qrDataUrl = this.generateQRCode(this.currentCurrency.address);
            }
        }
    },

    mounted() {
        if ( ! this.currentCurrency) {
            // not recognized currency; go to balance;
            this.$router.replace(`/${this.currentCurrencyId}/balance`);

            return false;
        }
    },

    methods: {
        generateQRCode(address) {
            const qrFactory = qrcode(0, 'L');
            qrFactory.addData(address);
            qrFactory.make();
            return qrFactory.createDataURL(24);
        },
        copyAddress() {
            window.cordova.plugins.clipboard.copy(this.currentCurrency.address);
            // navigator.notification.alert(
            //     'Address copied',  // message
            //     () => {},         // callback
            //     'Send',            // title
            //     'Done'                  // buttonName
            // );
            window.plugins.toast.show(
                'Address copied', // message
                'short', // toast length
                'bottom', // position
                function(a) { // toast success callback
                    console.log('toast success: ' + a)
                },
                function(b) { // toast error callback
                    alert('toast error: ' + b)
                },
            )
        }
    }
}
</script>

<style lang="scss" scoped src="./Receive.scss"/>
