<template>
    <div class="send">
        <Appbar can-navigate-back :title="`Send ${currentCurrency.displayName}`">
            <template v-slot:customizedTitle>
                Send <b>{{currentCurrency.symbol}}</b> 
                <!-- <p style="font-size: 12px; text-align: center; margin: 4px 0;">{{ isLocked == true ? '(Locked)' : '(Unlocked)' }}</p> -->
            </template>
        </Appbar>
        <div class="cryptocurrency-exchange circle-container">
            <div class="exchange-icon">
                <img :src="currentCurrency.icon" />
            </div>
            <div class="exchange-buttons">
                <button class="button" :class="{ transparent: ratesShownUSD }"  @click="ratesShownUSD = false">{{currentCurrencyId.toLocaleUpperCase()}}</button>
                <button class="button" :class="{ transparent: !ratesShownUSD }" @click="ratesShownUSD = true" v-if="rates[`${currentCurrencyId}`] > 0">USD</button>
            </div>
            <div class="exchange-amount" style="color:#2196f3;">
                {{ isLocked == false ? unlockedAmount : lockedAmount }}
            </div>
        </div>
        <div class="content-area">
            <div>
                <div class="input-entity">
                    <div class="btnArea text-align-right">
                        <button style="border-radius: 8px; background-color: #0c0f14; border: 1px solid #2196f3;" class="button mini" @click="putAmountToMaximum">
                            Max
                        </button>
                    </div>
                    <label>Amount to Send</label>
                    <input class="black" type="number" v-model="amount" min="0" placeholder="Enter amount to send"/>
                </div>

                <div class="input-entity scannable-qr-code">
                    <label>{{ harvest ? "TRON Address" : "To Address" }}</label>
                    <div class="input-area">
                        <input class="input-address black" v-model="address" :placeholder="addressPlaceholder" />
                        <!--
                        <button class="button scan-qr-code" @click.prevent="scan">
                            <img src="../assets/qrcode_icon.svg" width="auto">
                        </button>
                        -->
                    </div>
                </div>

<!--				<div class="input-entity watchable-password" v-if="!harvest">-->
<!--                    <label>One-time Password</label>-->
<!--                    <button v-if="!isOTPRequested" class="button font-size-positive secondary" @click.prevent="requestOTP">Request OTP to send {{currentCurrencyId.toLocaleUpperCase()}}</button>-->
<!--                    <div v-else class="input-area">-->
<!--                        <input :type="otpInputType" class="input-password" v-model="otp" :placeholder="`Enter your OTP to send ${currentCurrencyId.toLocaleUpperCase()}`" />-->
<!--                        <button class="button open-input-password" @click.prevent="otpIsOpen = !otpIsOpen">-->
<!--                            <img width="auto" v-if="otpIsOpen" src="../assets/input/ico_visibility-on.svg">-->
<!--                            <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">-->
<!--                        </button>-->
<!--                        <div class="btnArea spread">-->
<!--                            <div class="message">-->
<!--                                Enter the 6-digit code we sent to<br/>-->
<!--                                {{ profile.email }}.-->
<!--                            </div>-->
<!--                            <button class="button positive mini" :class="{ disabled: otpMatches }" @click="btnChkOTP"-->
<!--                                    :disabled="otpMatches">-->
<!--                                {{ (otpMatches) ? 'Confirmed' : 'Confirm'}}-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="input-entity watchable-password">
                    <label>Password</label>
                    <div class="input-area">
                        <input :type="passwordInputType" class="input-password" v-model="password"
                               placeholder="Enter your password"/>
                        <button class="button open-input-password" @click.prevent="passwordIsOpen = !passwordIsOpen">
                            <img width="auto" v-if="passwordIsOpen" src="../assets/input/ico_visibility-on.svg">
                            <img width="auto" v-else src="../assets/input/ico_visibility-off.svg">
                        </button>
						<div class="btnArea spread">
                            <div class="message">
                            </div>
                            <button class="button positive mini" :class="{ disabled: passwordChkd }" @click="confirmPassword"
                                    :disabled="passwordChkd">
                                {{ (passwordChkd) ? 'Confirmed' : 'Confirm'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container" v-if="!harvest">
                <button class="button positive font-size-positive" @click="send">Send</button>
            </div>
            <div v-else class="container">
                <button class="button positive font-size-positive" @click="send">Send</button>
            </div>
        </div>
        <FooterNavbar v-if="harvest" />
    </div>
</template>

<script>
import { sha3 } from 'sha3';

import Appbar from '../components/Appbar';
import CurrencyPage from '../mixins/CurrencyPage';
import FooterNavbar from "../components/FooterNavbar";
import {setAmount} from "../utils/common.js"
import httpClient, {API_HOST} from "../httpClient";

import {mapGetters} from "vuex";
import {toFixedNumber, toFixedNumberAndLocaleString} from "../components/utils";

export default {
    name: 'Send',
    mixins: [CurrencyPage],
    components: {FooterNavbar, Appbar },

    computed: {
        ...mapGetters(['currencies', 'rates', 'profile']),
        lockedAmount: {
            get: function() {
                return Number.parseInt(toFixedNumber(this.currentCurrency.locked, 0), 10);
            },
        },
        unlockedAmount: {
            get: function() {
                let unlockedAmount = Number((Math.floor(parseFloat(this.currentCurrency.balance) * 10 ** 8 ) / 10 ** 8)?.toFixed(8));

                if (unlockedAmount < 0) {
                    unlockedAmount = 0;
                }

                return unlockedAmount;
            },
        },
        passwordInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.passwordIsOpen);
            },
        },
        harvest() {
            return 'harvest' in this.$route.query;
        },
        otpInputType: {
            get: function() {
                return this.getTypeOfInputForPassword(this.otpIsOpen);
            },
        },
        currentAmount: {
            get: function() {
                if (!this.amount) {
                    return this.ratesShownUSD ? '0.00' : '0';
                }
                if (this.ratesShownUSD) {
                    let rated = Number.parseFloat(this.amount) * this.rates[`${this.currentCurrencyId}`];

                    return toFixedNumberAndLocaleString(rated, 2);
                } else {
                    return this.amount;
                }
            },
        },
        passwordMatches() {
            if ( ! this.passwordChkd ) {
                return false;
            }

            if ( this.passwordChkd !== this.password) {
                return false;
            }

            return true;
        },
        addressPlaceholder: {
            get: function() {
                if (this.harvest) {
                    return 'Enter Your Address';
                }
                return 'Enter address';
            },
        },
        ...mapGetters(['isQRScanning', 'currencies', 'rates', 'profile'])
    },
	
    data() {
        return {
            amount: '',
            address: '',
            otp: '',
            otpChkd: '',
            isOTPRequested: false,
            otpIsOpen: false,
            ratesShownUSD: false,
            passwordIsOpen: false,
            password: '',
			passwordChkd: false,
        }
    },

    beforeDestroy() {
        this.stopScan();
    },

    mounted() {
        if ( ! this.currentCurrency) {
            // not recognized currency; go to balance;
            this.$router.replace(`/${this.currentCurrencyId}/balance`);

            return false;
        }
    },

    methods: {
        scan() {
            let context = this;
            this.$store.dispatch('startQRScanning', {
                successCallback: (text) => {
                    context.address = text;
                }
            });
        },
        async stopScan() {
            this.$store.commit('setQRScanning', false);
        },
        async send() {

            if (this.currentCurrency.reliesOnTron) {
                if (parseFloat(this.currentCurrency.balance)-this.currentCurrency.fee < parseFloat(this.amount)) {
                    alert(`You need ${this.currentCurrency.fee} ${this.currentCurrencyId.toLocaleUpperCase()} to send ${this.currentCurrencyId.toLocaleUpperCase()}`);

                    return ;
                }
				if (parseFloat(this.amount) < 0) {
					alert('Invalid Amount')
					return;
				}
            } else {
				if (parseFloat(this.currentCurrency.balance)-this.currentCurrency.fee < parseFloat(this.amount) && parseFloat(this.amount) > 0) {
					alert(`You need ${this.currentCurrency.fee} ${this.currentCurrencyId.toLocaleUpperCase()} to send ${this.currentCurrencyId.toLocaleUpperCase()}`);

					return ;
				}
				if (parseFloat(this.amount) < 0) {
					alert('Invalid Amount')
					return;
				}
            }
            if(this.currentCurrency.address == this.address.trim()){
                alert('invalid address');
                return;
            }

            if (!this.address.trim()) {
                alert('invalid address');
                return;
            }
            if (parseFloat(this.amount) > parseFloat(this.currentCurrency.balance)) {
                alert('insufficient balance');
                return;
            }

            if (!this.isAddress(this.address)) {
                alert('Invalid address');
                return;
            }
			if (!this.passwordChkd) {
				alert('Please confirm your password');
				return;
			}

            this.$router.push({
                name: 'confirmSend', params: {
                    currency: this.currentCurrencyId,
                    address: this.address,
                    amount: this.amount,
                    otp: this.harvest ? '__bypass__' : this.otpChkd,
                },
            });
        },
        async confirmOTP() {
            try {
                await httpClient.post(API_HOST('/__authorize/otp/verify'), {
                    otp: this.otp
                });
                return true;
            } catch (e) {
                return false;
            }
        },
		async confirmPassword() {
			const email = this.profile.email;
			const { password } = this;
			await this.$store.dispatch('confirmPassword', { email, password });
			this.passwordChkd = true
		},
        /**
         * Checks if the given string is an address
         *
         * @method isAddress
         * @param {String} address the given HEX adress
         * @return {Boolean}
         */
        isAddress(address) {
            if (/^T[A-Za-z1-9]{33}$/i.test(address)) {
                return true;
            } else if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
                // check if it has the basic requirements of an address
                return false;
            } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
                // If it's all small caps or all all caps, return true
                return true;
            } else {
                // Otherwise check each case
                return true
            }
        },
        /**
         * Checks if the given string is a checksummed address
         *
         * @method isChecksumAddress
         * @param {String} address the given HEX adress
         * @return {Boolean}
         */
        isChecksumAddress(address) {
            // Check each case
            address = address.replace('0x','');
            var addressHash = sha3(address.toLowerCase());
            for (var i = 0; i < 40; i++ ) {
                // the nth letter should be uppercase if the nth digit of casemap is 1
                if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
                    return false;
                }
            }
            return true;
        },


        getTypeOfInputForPassword(isOpen) {
            if (isOpen) {
                return "text"
            } else {
                return "password";
            }
        },

        putAmountToMaximum() {
			//this.amount = this.isLocked == false ? this.unlockedAmount-this.currentCurrency.fee.toString() : this.lockedAmount.toString();
			const checkMax = setAmount(this.currentCurrency.balance, this.currentCurrency.fee)
			if (checkMax <= 0) { 
				return
			}
			this.amount =  Number(setAmount(this.currentCurrency.balance, this.currentCurrency.fee))
        },

        async btnChkOTP() {
            this.otpChkd = '';
            if (await this.confirmOTP()) {
                this.otpChkd = this.otp;
            } else {
                alert('Could not verify OTP. Please re-check OTP code you input.')
            }
        }
    }
};
</script>

<style lang="scss" src="./Send.scss" scoped>
</style>

<style lang="scss" scoped>
.input-entity {
    div.btnArea {
        margin-top: 16px;

        &.text-align-right {
            text-align: right;
        }

        &.spread {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .message {
            font-size: 12px;
            font-weight: bold;
        }
    }
}

div.container {
    display: flex;
    padding: 10px 16px 24px;

    button {
        flex: 1;
    }
}
</style>
