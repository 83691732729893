<template>
    <div class="balance">
        <Appbar can-navigate-back :title="`${currentCurrency.displayName}`">
            <template v-slot:customizedTitle>
                <b>{{currentCurrency.symbol}}</b> <!-- {{isLocked === true ? '(Locked)' : '(Unlocked)'}} -->
            </template>
        </Appbar>
        <div class="cryptocurrency-list" v-if="currentCurrency && currentCurrencyId">
            <div class="cryptocurrency">
                <div>
                    <div class="currency-id" >{{ currentCurrencyId.toLocaleUpperCase() }}</div>
                    <div class="currency-balance" v-if="rates[`${currentCurrencyId}`] > 0">
                        {{ currentCurrency.displayName }}
                    </div>
                    <br />
                    <div>{{ balanceAmountForDisplay }}</div>
                </div>
                <div class="graph-container">
                    <div>{{ currentCurrencyId.toLocaleUpperCase() }} </div>
                </div>
                <div class="graph-container">
                </div>
                <aside>
                    <img :src="currentCurrency.icon"/>
                </aside>
            </div>
        </div>

        <div style="text-align:left; font-size: 14px;" class="balance-padding-lr">
            <table>
                <!--
                <tr>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">Unlocked Amount</h3>
                    </td>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">: {{ unlockedAmount }} </h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">Locked Amount</h3>
                    </td>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">: {{ lockedAmount }} </h3>
                    </td>
                </tr>
                -->
                <tr v-show="isLocked === false">
                    <td>
                        <h3>Transaction Fee</h3>
                    </td>
                    <td>
                        <h3 v-if="currentCurrency.symbol == 'C3P'">: {{ currentCurrency.transactionFee }} {{ "DECE" }}</h3>
                        <h3 v-else>: {{ currentCurrency.transactionFee }} {{ currentCurrency.symbol }}</h3>
                    </td>
                </tr>
            </table>
			<ul>
				<li v-if="currentCurrency.symbol == 'ETH'">
					ERC20 Network
				</li>
				<li v-if="currentCurrency.symbol == 'PZBT'">
					BEP20 Network
				</li>
				<li v-if="currentCurrency.symbol == 'PZBT'">
					Before sending, please <span>add at least 0.0005 BNB to the PizzaBit address</span> for the fee.
				</li>
				<li v-if="currentCurrency.symbol == 'PZBT'">
					5000 PizzaBit(PZBT) will be given as PizzaBit promotional event.
				</li>
				<li v-if="currentCurrency.symbol == 'PZBT'">
					After proceeding withdrawal for one month, PizzaBit that have not been withdrawn in BitFrom wallet <span>will be sequentially removed</span> during the next month.
				</li>
			</ul>
			<div class="input-entity" v-if="currentCurrency.symbol == 'PZBT'">
				<label style="color: #2196f3">PizzaBit Contract Address</label>
				<div class="input-area">
					<input class="input-address black" ref="contractEl" value="0x2b59A2dBe57C65Bf22A33a6808c8bAF229808bC3" readonly style="color: #777"/>
					<p class="copy-info">Copy the address to use</p>
				</div>
			</div>
            <br>
        </div>

        <div class="btn-area" v-show="isLocked === true">
            <router-link :to="`/${currentCurrencyId}/locked/send`">
                <button class="button positive font-size-positive">Send</button>
            </router-link>
            <router-link :to="`/${currentCurrencyId}/locked/receive`">
                <button style="background: #0c0f14; color:white; border: 1px solid white;" class="button positive font-size-positive">Receive</button>
            </router-link>
        </div>

        <div class="btn-area" v-show="isLocked !== true">
            <router-link :to="`/${currentCurrencyId}/send`">
                <button class="button positive font-size-positive">Send</button>
            </router-link>
            <router-link :to="`/${currentCurrencyId}/receive`">
                <button class="secondary button positive font-size-positive">Receive</button>
            </router-link>
        </div>

        <header v-if="currentCurrency.symbol == 'BNB'">
            <div class="chart-area">
                <template v-if="datacollection">
                    <div class="direction-row">
                        <div style="font-weight: bold; font-size:14px; order: 2;">{{duration}}</div>
                        <div>$ {{ currentCurrencyId == 'eth' ? graphRange.max : graphRange.max?.toFixed(6) }}</div>
                    </div>
                    <div style="border-top: 1px solid #444d6e; border-bottom: 1px solid #444d6e;">
                        <line-chart
                            :chart-data="datacollection"
                            :height="200"
                            :options="options"
                            :range="graphRange"
                        ></line-chart>
                    </div>
                    <div class="min-value-area">$ {{ currentCurrencyId == 'eth' ? graphRange.min : graphRange.min?.toFixed(6) }}</div>
                </template>
                <template v-else-if="this.currentCurrency.isErc20 === false && !datacollection">
                    Graph data not available
                </template>
            </div>
            <!-- <div class="balance-area">
                <div class="circle-container">
                    <h4><small>{{currentCurrencyId.toLocaleUpperCase()}}</small> {{ currentCurrency.balance }}</h4>
                </div>
            </div> -->
        </header>

        <template>
        <div style="font-size: 12px;" class="balance-padding-lr setting-time-buttons" v-if="currentCurrency.symbol == 'BNB'">
            <table>
                <tr>
                    <td>
                        <button class="button" @click="settingSomething('1h', '24h')">1D</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('1d', '7d')">1W</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('3d', '30d')">1M</button>
                    </td>
                </tr>
            </table>
        </div>
        </template>

        <h2 class="transaction-history-title balance-padding-lr">Transaction History</h2>
        <div class="balance-padding-lr unemphasis" v-if="!transactions">
            Loading transaction history...
        </div>
        <section class="transaction-list balance-padding-lr" v-else-if="transactions.length >= 1">
            <div class="transaction" v-for="transaction in transactions" :key="transaction.txid" @click="openExplorer(transaction.txid)">
                <div class="txn-left">
                    <div class="txn-ico-area">
                        <img alt="receive" v-if="transaction.type === 'in'" src="../assets/ico/transaction/txn-receive.svg" />
                        <img alt="send" v-else-if="transaction.type === 'out'" src="../assets/ico/transaction/txn-send.svg" />
                        <img alt="fee" v-else src="../assets/ico/transaction/txn-fee.svg" />
                    </div>
                    <div class="txn-info-area">
                        <div style="word-break:break-all;" class="txn-info-txid">{{ transaction.txid }}</div>
                        <div class="txn-info-date">{{ getDateFormatted(transaction.timestamp) }}</div>
                    </div>
                </div>

                <div class="txn-right">
                    <span class="txn-amount">{{ calcDisplayAmount(transaction.value, currentCurrency.fractionDigits) }}</span> <span class="txn-unit">{{ getCurrencyUnit(transaction) }}</span>
                    <template v-if="transaction.fee">
                        <br />
                        <span class="txn-amount">Fee : {{ calcDisplayAmount(transaction.fee, currencies[`${keyCurrencyFee}`].fractionDigits + 4) }}</span> <span class="txn-unit">{{ keyCurrencyFee == 'pzbt' ? 'BNB' : keyCurrencyFee.toLocaleUpperCase()}}</span>
                    </template>
                </div>
            </div>
        </section>
        <div class="balance-padding-lr unemphasis" v-else>
            Transaction history is empty
        </div>
    </div>
</template>

<script>
import Appbar from "../components/Appbar";
import CurrencyPage from "../mixins/CurrencyPage";
import LineChart from '../components/LineChart.js'

import httpClient, { API_HOST } from "../httpClient";

import {mapGetters} from "vuex";
import {toFixedNumberAndLocaleString} from "../components/utils";

export default {
    mixins: [CurrencyPage],
    components: {
        Appbar,
        LineChart,
    },
    data() {
        return {
            transactions: [],
            datacollection: [],
            interval: '1h',
            duration: '24h',

            options: {
                animation: {
                    duration: 1, // disable animation
                },
                legend: { //hides the legend
                    display: false,
                },
                scales: { //hides the y axis
                    yAxes: [{
                        display: false,
                        ticks: {
                            fontSize: 15,
                            maxTicksLimit: 1,
                            fontColor: 'white',
                            callback: function(value) {
                                return '$' + toFixedNumberAndLocaleString(value, 2);
                            },
                        },
                        gridLines: {
                            color: "#2196f3",
                        },
                    }],
                    xAxes: [{
                        display: false
                    }]
                }
            },
            graphRange:{
                min: null,
                max: null,
            },
        };
    },
    computed: {
        ...mapGetters(['currencies', 'rates']),
        lockedAmount: {
            get: function() {
                return this.currentCurrency.locked;
            },
        },
        unlockedAmount: {
            get: function() {
                let unlockedAmount = this.currentCurrency.balance - this.lockedAmount;

                if (unlockedAmount < 0) {
                    unlockedAmount = 0;
                }

                return unlockedAmount;
            },
        },
        balanceAmountForDisplay: {
            get: function () {
                return toFixedNumberAndLocaleString(this.currentCurrency.balance, this.currentCurrency.fractionDigits);
            },
        },
        balancePriceForDisplay: {
            get: function () {
                return toFixedNumberAndLocaleString(this.currentCurrency.balance * this.rates[`${this.currentCurrencyId}`], 2);
            },
        },
        keyCurrencyFee: {
            get: function () {
                let currencyFeeUnit = '';
                if (this.currentCurrency.reliesOnTron) {
                    currencyFeeUnit = 'eth';
                } else {
                    currencyFeeUnit = this.currentCurrencyId;
                }

                return currencyFeeUnit;
            }
        },
    },
    mounted() {
        //if ( ! Object.keys(this.currencies).includes(this.currentCurrencyId)) {
        if ( ! this.currentCurrency) {
            // not recognized currency; go to home;
            this.$router.replace('/');

            return false;
        }

        this.filldata();
        this.fetchTxHistory();
    },
    methods: {
        async fetchTxHistory() {
            const { data } = await httpClient.get(API_HOST(`/api/${this.currentCurrencyId}/history`));
            console.log(data);
            data.sort(function(a,b) { return a.timestamp - b.timestamp;});
            this.transactions = data;
        },
        openExplorer(txid) {
            if(this.currentCurrencyId === 'eth') {
                this.openETHExplorer(txid);
            } else if(this.currentCurrencyId === 'fr') {
                this.openSTXExplorer(txid);
            } else if(this.currentCurrencyId === 'btc') {
                this.opeBTCExplorer(txid);
            } else {
                this.openETHExplorer(txid);
            }
        },
        openETHExplorer(txid) {
            window.open(`https://etherscan.io/address/${txid}`, '_self ', 'location=yes');
        },
        openSTXExplorer(txid) {
            window.open(`https://explorer.hiro.so/txid/${txid}?chain=mainnet`, '_self ', 'location=yes');
        },
        opeBTCExplorer(txid) {
            window.open(`https://www.blockchain.com/explorer/transactions/btc/${txid}`, '_self ', 'location=yes');
        },
        settingSomething(interval, duration) {
            this.duration = duration;
            this.interval = interval;
            console.log(`${interval}, ${duration}`);
            this.filldata();
        },
        async filldata() {
            try {
                const { data } = await httpClient.get(API_HOST(`/api/system/rate/${this.currentCurrencyId}/${this.interval}`));

                let minVal =  99999999;
                let maxVal = -99999999;
                data.map(val => {
                    val = Number(val);
                    if (val < minVal) {
                        minVal = val;
                    }
                    if (val > maxVal) {
                        maxVal = val;
                    }
                });

                this.graphRange = {
                    min: minVal,
                    max: maxVal
                };

                // value changes not reflected on LineChart.js
                // this.options.scales.yAxes[0].ticks.min = minVal;
                // this.options.scales.yAxes[0].ticks.max = maxVal;

                this.datacollection = {
                    labels: [...new Array(data.length)],
                    datasets: [
                        {
                            type: 'line',
                            lineTension: 0.3,
                            backgroundColor: 'transparent',
                            borderColor: "#2196f3",
                            data: data,
                            pointRadius: 0,
                        }
                    ],
                }
            } catch (e) {
                this.datacollection = null;
            }
        },

        calcDisplayAmount(amount, fractionDigits) {
            return toFixedNumberAndLocaleString(amount, fractionDigits)
        },
        
        getDateFormatted(dateStr){
            try {
                let moment = this.$moment(dateStr);
                return moment.format('YYYY-MM-DD HH:mm:ss');
            } catch (e) {
                // do nothing
            }

            // cannot process anything: return raw;
            return dateStr;
        },

        getCurrencyUnit(txn) {
            let currencyId = '';
            if (txn.type === 'in' || txn.type === 'out') {
                currencyId = this.currentCurrencyId;
            } else if (this.currentCurrency.reliesOnTron) {
                currencyId = this.currentCurrency.symbol;
            } else {
                currencyId = this.currentCurrencyId;
            }

            return currencyId.toLocaleUpperCase();
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../const';

header:not(.app-bar) {
    position: relative;

    pointer-events: none;
    div.balance-area {
        position: absolute;
        left: 0; top: 0;
        width: 100%;
    }
}

.balance-padding-lr {
    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: bold;
	span {
		color: $BASE_SUB_COLOR_BRIGHTER
	}
	.input-entity {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0 16px;
		margin-bottom: 20px;

		input.black {
			width: 100%;
			padding-right: 56px;
			background-color: #1b1e22;
			border: 1px solid #3c4850;
			color: white;
		}
		@media (max-width: 500px) {
			input.black {
				font-size: .8rem;
			}
		}
		@media (max-width: 450px) {
			input.black {
				font-size: .7rem;
			}
		}
		@media (max-width: 400px) {
			input.black {
				font-size: .5rem;
			}
		}
		.copy-info{
		text-align: center;
		opacity: 80%;
		}
	}
}

.cryptocurrency-list {
    padding: 16px;
    .cryptocurrency {
        align-items: flex-start;
        div {
            flex: 1;
        }
        .info-container{
        display: flex; 
        gap:8px;
        align-items: flex-start;
        }
        .graph-container{
            align-self: end;
        }
    }
}

.setting-time-buttons table tr td button{
    background-color: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;
    color: #acacac;
    
    &:focus{
        border: 1px solid $BASE_SUB_COLOR_BRIGHTER;
        color: $BASE_SUB_COLOR_BRIGHTER;
    }
}

.btn-area {
    display: flex;
    padding: 0 16px;
    margin-bottom: 30px;
    & > * {
        flex: 1;
        &:first-child { margin-right: 8px; }
        &:last-child  { margin-left: 8px;  }
        button { width: 100%; }
    }
}

.transaction-history-title {
    margin-top: 35px;
    text-align: center;
    color: $BASE_SUB_COLOR;
    font-size: 18px;
    font-weight: 900;
}

.transaction-list {
    // padding: 8px;

    .transaction {
        // each transaction history
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        .txn-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 60%;

            .txn-ico-area {
                // only 1 icon
                margin-right: 15px;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }

            .txn-info-area {
                // refer .txn-ico-area
                max-width: calc(100% - (30px + 15px));

                display: flex;
                flex-direction: column;
                justify-content: left;

                font-size: 14px;

                .txn-info-txid {
                    max-width: 100%;

                    font-family: monospace;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .txn-right {
            //amount and unit

            max-width: 39%;
            font-size: 14px;

            text-align: right;

            .txn-amount {
                font-weight: bold;
            }
            .txn-unit {
                opacity: 0.6;
            }
        }
    }
}

.unemphasis {
    opacity: 0.6;
    margin-top: 10px;
}

.chart-area {
    padding: 0 16px;
    margin-bottom: 15px;

    canvas {
        max-height: 180px !important;
        width: calc(100% - (16px * 1)) !important;
    }
}

.direction-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.min-value-area {
    margin-top: 8px;
}

</style>
