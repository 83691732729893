<template>
    <div class="complete-send">
        <Appbar can-navigate-back title="Finish" />
        <div class="page-padding-lr pageRoot">
            <div class="fullPage center">
                <img class="checkMark" src="../assets/ico/complete/checkmark-in-circle.svg">
                <div class="completeMsg">
                    Transaction request<br />
                    Complete
                </div>
            </div>
            <button class="button positive font-size-positive" @click="$router.push('/')">
                Go to Home
            </button>
        </div>
    </div>
</template>

<script>
import Appbar from '../components/Appbar';
import CurrencyPage from "../mixins/CurrencyPage";

export default {
    name: 'CompleteSend',
    mixins: [CurrencyPage],
    components: { Appbar },

    computed: {
        toAddress() {
            return this.$route.params['address'];
        },
        amount() {
            return this.$route.params['amount'];
        },
        balance() {
            return this.currentCurrency.balance;
        },
    },

    mounted() {
        if (!this.toAddress) {
            this.$router.replace('/');
        }
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.complete-send {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: calc(100vh - 56px);
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .pageRoot {
        height: 100%;

        .fullPage {
            display:flex;
            flex-direction: column;
            height: 100%;

            &.center {
                justify-content: center;
                align-items: center;
            }

            img.checkMark {
                width: 78px;
                height: 78px;
            }

            div.completeMsg {
                margin-top: 22px;

                font-size: 20px;
                text-align: center;
            }
        }
    }
}
</style>

